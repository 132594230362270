import Testimonial from "./Testimonial";
import "./index.css";
import React, { Component } from "react";
import semicircle from "./assets/images/semicircle.png";
import { useEffect, useState } from "react";
import { apiBase } from "../../config/routes";
import Slider from "react-slick";


const Testimonials = () => {
  const [testimonial, setTestimonia] = useState([]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1294,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    loadTestomonial((result) => {
      try {
        console.log("result");
        setTestimonia(JSON.parse(result));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  return (
    <div style={{ marginTop: 30, overflow: "hidden", marginBottom: 80 }}>
      <div>
        <div className="testimonials-header">Testimonials</div>
        <div style={{}}>
          <Slider {...settings}>
            {testimonial.map((d, i) => {
              return (
                <div style={{}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Testimonial
                      key={i}
                      image={semicircle}
                      name={d.name}
                      profileIamge={d.profilePic}
                      quote={d.quote}
                    />
                  </div>
                </div>
              );
            })}
            <div></div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;

function loadTestomonial(cb) {
  var myHeaders = new Headers();

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(apiBase + "/testimonials", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}

//let e = () => (
//   <div
//     className="testimonials-container"
//     style={{ overflow: "hidden", border: "1px solid red" }}
//   >
//     <div className="testimonials-header">Testimonials</div>
//     <div style={{ overflow: "hidden", border: "1px solid red", width: "100%" }}>
//       {/* <Responsive /> */}
//       <Slider {...settings}>
//         {testimonial.map((d, i) => {
//           return (
//             <div style={{}}>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                 }}
//               >
//                 <Testimonial
//                   key={i}
//                   image={semicircle}
//                   name={d.name}
//                   profileIamge={d.profilePic}
//                   quote={d.quote}
//                 />
//               </div>
//             </div>
//           );
//         })}
//       </Slider>
//     </div>

//     <div
//       style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
//     >
//       <div style={{ flex: 1 }}></div>
//     </div>
//   </div>
// );
