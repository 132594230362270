import TabButton from "./TabButton";

import buildingUpcoming from "./assets/tabButtonImages/buildingUpcoming.png";
import buildingOngoing from "./assets/tabButtonImages/building3.png";
import buildingCompleted from "./assets/tabButtonImages/buildingCompleted.png";
import buildingUpcomingActive from "./assets/tabButtonImages/buildingUpcomingActive.png";
import buildingOngoingActive from "./assets/tabButtonImages/buildingOngoingActive.png";
import buildingCompletedActive from "./assets/tabButtonImages/building2.png";
import PropertiesCards from "./ProjectCards";
import { apiBase } from "../../config/routes";
import PropertiesCards1 from "./ProjectCards1";
import { useState, useEffect } from "react";
const Projects = () => {
  const [gatewayData, setGatewayData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(3);
  useEffect(() => {
    getData((result) => {
      setGatewayData(JSON.parse(result));
      console.log(result);
    });
  }, []);
  return (
    <div
      id="properties"
      style={
        {
          // marginBottom: 10,
        }
      }
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: 40,
          lineHeight: "47px",
          color: "#212325",
          textAlign: "center",
          marginBottom: 70,
          paddingTop: 70,
        }}
      >
        Projects
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            // transform: "scale(.5)",
          }}
        >
          <TabButton
            title={"Upcoming Projects"}
            icon={buildingUpcoming}
            iconActive={buildingUpcomingActive}
            active={selectedTab === 1}
            onClick={(e) => {
              setSelectedTab(1);
              getData((result) => {
                setGatewayData(JSON.parse(result));
                console.log(result);
              });
            }}
            bgColor={selectedTab === 1 ? "#FFE5BE" : "#EEEEEE"}
            selected={selectedTab === 1}
          />
          <TabButton
            title={"Ongoing Projects"}
            icon={buildingOngoing}
            iconActive={buildingOngoingActive}
            onClick={(e) => {
              setSelectedTab(2);
              getData((result) => {
                setGatewayData(JSON.parse(result));
                console.log(result);
              });
            }}
            active={selectedTab === 2}
            bgColor={selectedTab === 2 ? "#FFE5BE" : "#EEEEEE"}
            selected={selectedTab === 2}
          />
          <TabButton
            title={"Completed Projects"}
            icon={buildingCompleted}
            iconActive={buildingCompletedActive}
            bgColor={selectedTab === 3 ? "#FFE5BE" : "#EEEEEE"}
            active={selectedTab === 3}
            onClick={(e) => {
              setSelectedTab(3);
              getData((result) => {
                setGatewayData(JSON.parse(result));
                console.log(result);
              });
            }}
            selected={selectedTab === 3}
          />
        </div>
      </div>
      {/* <PropertiesCards /> */}
      <PropertiesCards1 gatewayData={gatewayData} selectedTab={selectedTab} />
    </div>
  );
};
export default Projects;

function getData(cb) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(apiBase + "/projects", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
