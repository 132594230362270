import "./BannerPrimary.css";
import BannerPrimaryInteractiveImage from "./BannerPrimaryInteractiveImage";

const BannerPrimary = () => {
  return (
    <div className="banner-primary" id="banner-primary">
      <div className="banner-primary-container">
        <div className="banner-primary-section-1">
          <div  className="banner-primary-section-1-margin" style={{ margin: 70 }}>
            <div className="banner-primary-header">
              We{" "}
              <span
                style={{ background: "#feedd7", padding: 5, fontWeight: 500 }}
              >
                Plan, Build
              </span>{" "}
              and Oversees Construction of your{" "}
              <span
                style={{ background: "#cccbca", padding: 5, fontWeight: 500 }}
              >
                Buildings
              </span>
            </div>
            <div className="banner-primary-content">
              The design of buildings and the space within the site surrounding
              the buildings that have human occupancy or use as their principal
              purpose
            </div>
            <div className="banner-primary-button">
              <a
                href="/#contact-info"
                style={{ textDecoration: "none", color: "#222" }}
              >
                Make Schedule to talk
              </a>
            </div>
          </div>
        </div>
        <div className="banner-primary-section-2">
          <BannerPrimaryInteractiveImage />
        </div>
      </div>
    </div>
  );
};
export default BannerPrimary;
