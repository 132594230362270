const KPICard = ({ title, image, count }) => {
  return (
    <div style={{ width: 200 }}>
      <div style={{ padding: 20, textAlign: "center" }}>
        <img src={image} alt={title} />
      </div>
      <div
        style={{
          padding: 10,
          textAlign: "center",
          fontWeight: 500,
          fontSize: 19.2794,
          lineHeight: "141.7%",
          color: "#fff",
        }}
      >
        {count}
      </div>
      <div
        style={{
          padding: 10,
          textAlign: "center",
          fontWeight: 500,
          fontSize: 19.2794,
          lineHeight: "141.7%",
          color: "#fff",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default KPICard;
