import { redirect } from "react-router-dom";

const TabButton = ({
  icon,
  iconActive,
  active,
  title,
  bgColor = "#EEEEEE",
  onClick = (e) => {},
}) => {
  return (
    <div
      style={{
        background: bgColor,
        display: "flex",
        margin: 13,
        borderRadius: 5,
        cursor: "pointer",
        // border: " solid 1px",
        minWidth: 278,
      }}
      onClick={onClick}
    >
      <div
        style={{
          padding: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={!active ? icon : iconActive} alt={title} />
      </div>
      <div
        style={{
          display: "flex",
          padding: 10,
          alignItems: "center",
          justifyContent: "center",
          fontWeight: 500,
          fontSize: 14,
          lineHeight: "9px",
          paddingRight: 30,
          color: "#090909",
        }}
      >
        {title}
      </div>
    </div>
  );
};
export default TabButton;
