import quoteleft from "./assets/images/quoteleft.png";
import quoteright from "./assets/images/quoteright.png";
import rectangle from "./assets/images/rectangle.png";
import "./testimonial.css";
const Testimonial = ({ image, profileIamge, name, quote }) => {
  return (
    <div className="testimonial-card" style={{ height: 281 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: 20, textAlign: "center" }}>
          <img src={image} alt="ellip" />
          <div
            className="jhon-image"
            style={{
              // border: "1px solid red",
              width: 72,
              height: 72,
              borderRadius: 100,
              overflow: "hidden",
              marginLeft: 5,
              position: "relative",
              top: -23,
              // marginLeft: 5,
            }}
          >
            <img src={profileIamge} alt="jhon" width={72} height={72} />
          </div>
        </div>
      </div>
      <div className="quote-left">
        <img src={quoteleft} alt="" />
        <div className="quote-left-1">
          <img src={quoteleft} alt="" />
        </div>
      </div>
      <div className="quote-right-2">
        <img src={quoteright} alt="" />
        <div className="quote-right-3">
          <img src={quoteright} alt="" />
        </div>
      </div>
      <p style={{ minHeight: 60 }}>{quote || "--"}</p>
      <div className="rectangle-image"></div>
      <div style={{ display: "flex", padding: 20 }}>
        <div style={{ flex: 1 }}></div>
        <img
          src={rectangle}
          alt="rect"
          style={{ width: 10, height: 4, position: "relative", top: 14 }}
        />
        <div style={{ padding: 5 }}>{name}</div>
      </div>
    </div>
  );
};

export default Testimonial;
