const WorkingProcessCard = ({ icon, title, description }) => {
  return (
    <div style={{ display: "flex", marginBottom: 10 }}>
      <div
        style={{
          padding: "0px 17px",
          margin: 17,
          width: 45,
          maxWidth: 45,
          borderRight: "1px solid #FBE0BA",
        }}
      >
        <img src={icon} alt={title} />
      </div>
      <div>
        <div
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "141.7%",

            letterSpacing: "0.05em",
            marginTop: 10,
            color: "#000000",
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "141.7%",
            letterSpacing: "0.05em",
            marginTop: 5,
            color: "rgba(0, 0, 0, 0.4)",
          }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};
export default WorkingProcessCard;
