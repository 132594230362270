import "./index.css";
import LogoImage from "./assets/icons/logo.png";
import int from "./assets/icons/int.png";
import facebook from "./assets/icons/facebook.png";
import insta from "./assets/icons/insta.png";
import twitter from "./assets/icons/twitter.png";
import phone from "./assets/icons/phone.png";
import inbox from "./assets/icons/inbox.png";
import location from "./assets/icons/location.png";
import { useEffect, useState } from "react";
import { apiBase } from "../../config/routes";
const Footer = () => {
  const [reachUs, setReachUs] = useState();
  useEffect(() => {
    loadReachUs((result) => {
      try {
        setReachUs(JSON.parse(result));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-content-container">
          <div className="footer-contentSection-1">
            <div className="footer-logo-container">
              <img src={LogoImage} alt="logo" />
            </div>
            <div className="footer-socialmedia-link-container-1">
              <div>
                <a href={"https://www.linkedin.com/"} target="_new">
                  <img
                    className="footer-social-link-icons"
                    src={int}
                    alt="insta"
                  />
                </a>
                <a href={"https://www.facebook.com/"} target="_new">
                  <img
                    className="footer-social-link-icons"
                    src={facebook}
                    alt="facebook"
                  />
                </a>
                <a href={"https://www.twitter.com/"} target="_new">
                  <img
                    className="footer-social-link-icons"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a href={"https://www.instagram.com/"} target="_new">
                  <img
                    className="footer-social-link-icons"
                    src={insta}
                    alt="insta"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-contentSection-2">
            <div className="footer-header">Quick Links</div>
            <div className="footer-header">
              <a href="#banner-primary" className="footer-links">
                Home
              </a>
            </div>
            <div className="footer-header">
              <a href="#about-us" className="footer-links">
                About
              </a>
            </div>
            <div className="footer-header">
              <a href="#properties" className="footer-links">
                Projects
              </a>
            </div>
            <div className="footer-header">
              <a href="#contact-info" className="footer-links">
                Contact Us
              </a>
            </div>
          </div>
          <div className="footer-contentSection-3">
            <div className="footer-header">Reach Us</div>
            <div className="footer-reach-us-content-container">
              <img className="footer-social-icons" src={phone} alt="phone" />
              <div className="footer-content">
                {reachUs?.phones || "+918496894702 | +918792694702"}
              </div>
            </div>
            <div className="footer-reach-us-content-container">
              <img className="footer-social-icons" src={inbox} alt="inbox" />
              <div className="footer-content">
                {reachUs?.emails || "sunilraj@baladibuilders.com"}
              </div>
            </div>
            <div className="footer-reach-us-content-container">
              <img
                className="footer-social-icons"
                src={location}
                alt="location"
              />

              <div className="footer-content">
                {reachUs?.address ||
                  " No-657, 3rd Stage, 3rd Block, Basaveshwara Nagar, Bangalore-560079"}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-socialmedia-link-container-2">
          <div>
            <a href={"https://www.https://www.instagram.com/"}>
              <img className="footer-social-link-icons" src={int} alt="insta" />
            </a>
            <img
              className="footer-social-link-icons"
              src={facebook}
              alt="facebook"
            />
            <img
              className="footer-social-link-icons"
              src={twitter}
              alt="twitter"
            />
            <img className="footer-social-link-icons" src={insta} alt="insta" />
          </div>
        </div>
        <div
          className="footer-devider"
          style={
            {
              //   marginBottom: 10,
            }
          }
        ></div>
        <div className="footer-bottom">
          Copyright 2020-2022 · BALADI BUILDERS & DEVELOPERS.®· All Rights
          Reserved
        </div>
      </div>
    </div>
  );
};
export default Footer;

function loadReachUs(cb) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(apiBase + "/reachus/1", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
