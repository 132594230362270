import "./services.css";
const ServiceCard = ({ mainTitle, subTitle, image }) => {
  return (
    <div className="service-card-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          // border: "1px solid red",
        }}
      >
        <img src={image} alt="icon" />
      </div>
      <div className="service-card-main-title">{mainTitle}</div>
      <div className="service-card-sub-title">{subTitle}</div>
    </div>
  );
};

export default ServiceCard;
