import "./App.css";
import React from "react";
import AboutUs from "./components/AboutUs";
import BannerPrimary from "./components/BannerPrimary";
import Header from "./components/Header";
import KPIBanner from "./components/KPIBanner/index.js";
import Services from "./components/Services";
import WhyUS from "./components/WhyUS";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import ContactInfo from "./components/ContactInfo";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  useParams,
} from "react-router-dom";
import Projectdetails from "./components/ProjectDetails";
function Home() {
  return (
    <div className="App">
      <Header />
      <BannerPrimary />
      <Services />
      <WhyUS />
      <AboutUs />
      <KPIBanner />
      <Projects />
      <Testimonials />
      <ContactInfo />
      <Footer />
    </div>
  );
}
const About = () => {
  let { id } = useParams();
  return (
    <div className="App" style={{ backgroundColor: "#f5f5f5" }}>
      <Header />
      <Projectdetails id={id} />
      <ContactInfo />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/project/:id",
    element: <About />,
  },
]);
const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
export default App;
