import React, { useState } from "react";
import PropTypes from "prop-types";
import "./header.css";
import logo from "../../assets/logos/header-logo.svg";
import ham from "../../assets/icons/hamburger.svg";
import HeaderLink from "./HeaderLink";

/**
 * Primary UI component for user interaction
 */
const Header = ({ primary, backgroundColor, size, label, ...props }) => {
  const [selectedHeaderLink, setSelectedHeaderLink] = useState("home");
  const [showHeaderLinkDropdown, setShowHeaderLinkDropdown] = useState(false);
  return (
    <div className="header">
      <div className="header-body ">
        <div className="header-logo-container">
          <a
            href="/#banner-primary"
            style={{ textDecoration: "none", color: "#222" }}
          >
            <img src={logo} alt={"Baladi"} />
          </a>
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{}} className="link-container">
          <HeaderLink
            title={"Home"}
            link={"/#banner-primary"}
            active={selectedHeaderLink === "home"}
            onClick={(e) => {
              setSelectedHeaderLink("home");
            }}
          />
          <HeaderLink
            link={"/#about-us"}
            title={"About"}
            active={selectedHeaderLink === "about"}
            onClick={(e) => {
              setSelectedHeaderLink("about");
            }}
          />
          <HeaderLink
            link={"/#properties"}
            title={"PROJECTS"}
            active={selectedHeaderLink === "properties"}
            onClick={(e) => {
              setSelectedHeaderLink("properties");
            }}
          />
          <HeaderLink
            link={"/#contact-info"}
            title={"CONTACT"}
            active={selectedHeaderLink === "contact"}
            onClick={(e) => {
              setSelectedHeaderLink("contact");
            }}
          />
        </div>
        <div
          style={{}}
          className="hamburger-link"
          onClick={(e) => setShowHeaderLinkDropdown(!showHeaderLinkDropdown)}
        >
          <img src={ham} alt="more" width="50" height="50" />
        </div>
      </div>
      {showHeaderLinkDropdown && (
        <div style={{ background: "#000", margin: 20 }}>
          <div style={{ padding: 10 }}>
            <HeaderLink
              title={"Home"}
              active={selectedHeaderLink === "home"}
              onClick={(e) => {
                setSelectedHeaderLink("home");
              }}
            />
          </div>
          <div style={{ padding: 10 }}>
            <HeaderLink
              title={"About"}
              active={selectedHeaderLink === "about"}
              onClick={(e) => {
                setSelectedHeaderLink("about");
              }}
            />{" "}
          </div>
          <div style={{ padding: 10 }}>
            <HeaderLink
              title={"PROPERTIES"}
              active={selectedHeaderLink === "properties"}
              onClick={(e) => {
                setSelectedHeaderLink("properties");
              }}
            />{" "}
          </div>
          <div style={{ padding: 10 }}>
            <HeaderLink
              title={"CONTACT"}
              active={selectedHeaderLink === "contact"}
              onClick={(e) => {
                setSelectedHeaderLink("contact");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
Header.propTypes = {};

Header.defaultProps = {};
