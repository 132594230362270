import React from "react";
import "./projectCards.css";

import property from "./assets/images/property.png";
import property1 from "./assets/images/property1.png";
import property2 from "./assets/images/property2.png";
import property3 from "./assets/images/property3.png";

let gatewayData = [
  {
    image: property,
    propertycontent: "RashiGateway",
    quote: "Located adjacent to the Bangalore-Pune highway in Nelamangala...",
    propertyamount: "₹ 67.35 L - 1.6 Cr",
    info: "More Info",
  },
  {
    image: property1,
    propertycontent: "RashiGateway",
    quote: "Located adjacent to the Bangalore-Pune highway in Nelamangala...",
    propertyamount: "₹ 67.35 L - 1.6 Cr",
    info: "More Info",
  },

  {
    image: property2,
    propertycontent: "RashiGateway",
    quote: "Located adjacent to the Bangalore-Pune highway in Nelamangala...",
    propertyamount: "₹ 67.35 L - 1.6 Cr",
    info: "More Info",
  },
  {
    image: property3,
    propertycontent: "RashiGateway",
    quote: "Located adjacent to the Bangalore-Pune highway in Nelamangala...",
    propertyamount: "₹ 67.35 L - 1.6 Cr",
    info: "More Info",
  },
];

function PropertiesCards() {
  return (
    <div>
      <div className="property-cards">
        {gatewayData.map((d, index) => {
          return (
            <Properties
              key={index}
              image={d.image}
              propertycontent={d.propertycontent}
              propertyamount={d.propertyamount}
              quote={d.quote}
              info={d.info}
            />
          );
        })}
      </div>
    </div>
  );
}
const Properties = ({
  image,
  propertycontent,
  propertyamount,
  quote,
  info,
}) => {
  return (
    <div className="proparty-card">
      <img
        style={{ width: 200, height: 130, borderRadius: "0,20px" }}
        src={image}
        alt="ellip"
      />
      <div className="property-content">{propertycontent}</div>
      <p>{quote}</p>
      <div className="property-amount">{propertyamount}</div>
      <div className="project-card-button">{info}</div>
    </div>
  );
};

export default PropertiesCards;
