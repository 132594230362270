import "../Services/services.css";

import { useEffect, useRef, useState } from "react";
import { apiBase } from "../../config/routes";
import Slider from "react-slick";

const Services = ({ gatewayData, selectedTab }) => {
  const sliderRef = useRef();

  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 80,
      }}
    >
      <div
        style={{
          display: "flex",
          minWidth: 100,
          flex: 1,
        }}
      >
        <div
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            // width: 0,
          }}
        >
          <div
            style={{
              background: "#fff",
              boxShadow: "1px 2px 4px 2px #cccccc8c",
              minWidth: 50,
              minHeight: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 100,
              cursor: "pointer",
              position: "relative",
              // left: -5,
              zIndex: 999,
            }}
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickPrev();
            }}
          >
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.1875 1.47919L1.16667 9.50002L9.1875 17.5209"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {true && (
          <div style={{ flex: 1, minWidth: 200 }}>
            <div
              style={{
                margin: "auto",
              }}
            >
              <Slider
                ref={sliderRef}
                {...{
                  dots: false,
                  infinite: true,
                  speed: 500,
                  // lazyLoad: true,
                  slidesToShow:
                    gatewayData.filter((d) => {
                      if (selectedTab === 1) return d.status === "upcoming";
                      if (selectedTab === 2) return d.status === "ongoing";
                      if (selectedTab === 3) return d.status === "completed";
                    }).length > 4
                      ? 4
                      : gatewayData.filter((d) => {
                          if (selectedTab === 1) return d.status === "upcoming";
                          if (selectedTab === 2) return d.status === "ongoing";
                          if (selectedTab === 3)
                            return d.status === "completed";
                        }).length,
                  slidesToScroll:
                    gatewayData.filter((d) => {
                      if (selectedTab === 1) return d.status === "upcoming";
                      if (selectedTab === 2) return d.status === "ongoing";
                      if (selectedTab === 3) return d.status === "completed";
                    }).length > 4
                      ? 4
                      : gatewayData.filter((d) => {
                          if (selectedTab === 1) return d.status === "upcoming";
                          if (selectedTab === 2) return d.status === "ongoing";
                          if (selectedTab === 3)
                            return d.status === "completed";
                        }).length,
                  initialSlide: 0,
                  responsive: [
                    {
                      breakpoint: 1330,
                      initialSlide: 0,
                      settings: {
                        slidesToShow:
                          gatewayData.filter((d) => {
                            if (selectedTab === 1)
                              return d.status === "upcoming";
                            if (selectedTab === 2)
                              return d.status === "ongoing";
                            if (selectedTab === 3)
                              return d.status === "completed";
                          }).length > 3
                            ? 3
                            : gatewayData.filter((d) => {
                                if (selectedTab === 1)
                                  return d.status === "upcoming";
                                if (selectedTab === 2)
                                  return d.status === "ongoing";
                                if (selectedTab === 3)
                                  return d.status === "completed";
                              }).length,
                        slidesToScroll:
                          gatewayData.filter((d) => {
                            if (selectedTab === 1)
                              return d.status === "upcoming";
                            if (selectedTab === 2)
                              return d.status === "ongoing";
                            if (selectedTab === 3)
                              return d.status === "completed";
                          }).length > 3
                            ? 3
                            : gatewayData.filter((d) => {
                                if (selectedTab === 1)
                                  return d.status === "upcoming";
                                if (selectedTab === 2)
                                  return d.status === "ongoing";
                                if (selectedTab === 3)
                                  return d.status === "completed";
                              }).length,
                        // infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow:
                          gatewayData.filter((d) => {
                            if (selectedTab === 1)
                              return d.status === "upcoming";
                            if (selectedTab === 2)
                              return d.status === "ongoing";
                            if (selectedTab === 3)
                              return d.status === "completed";
                          }).length > 2
                            ? 2
                            : gatewayData.filter((d) => {
                                if (selectedTab === 1)
                                  return d.status === "upcoming";
                                if (selectedTab === 2)
                                  return d.status === "ongoing";
                                if (selectedTab === 3)
                                  return d.status === "completed";
                              }).length,
                        slidesToScroll:
                          gatewayData.filter((d) => {
                            if (selectedTab === 1)
                              return d.status === "upcoming";
                            if (selectedTab === 2)
                              return d.status === "ongoing";
                            if (selectedTab === 3)
                              return d.status === "completed";
                          }).length > 2
                            ? 2
                            : gatewayData.filter((d) => {
                                if (selectedTab === 1)
                                  return d.status === "upcoming";
                                if (selectedTab === 2)
                                  return d.status === "ongoing";
                                if (selectedTab === 3)
                                  return d.status === "completed";
                              }).length,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 800,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ],
                }}
              >
                {/* {JSON.stringify(gatewayData)} */}
                {gatewayData &&
                  gatewayData
                    .filter((d) => {
                      if (selectedTab === 1) return d.status === "upcoming";
                      if (selectedTab === 2) return d.status === "ongoing";
                      if (selectedTab === 3) return d.status === "completed";
                    })
                    .map((d, index) => {
                      return (
                        <div style={{}}>
                          <div className="slider-service-card-holder">
                            {/* <div style={{ fontSize: 10 }}>{d.images[0]}</div> */}
                            <Properties
                              pid={d.id}
                              key={index}
                              images={d.images[0]}
                              title={d.title}
                              details={d.details}
                              price={d.price}
                              info={d.info}
                            />
                          </div>
                        </div>
                      );
                    })}
              </Slider>
            </div>
          </div>
        )}
        <div
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            // left: -60,
          }}
        >
          <div
            style={{
              background: "#fff",
              boxShadow: "1px 2px 4px 2px #cccccc8c",
              width: 50,
              height: 50,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
              borderRadius: 100,
            }}
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickNext();
            }}
          >
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.3125 17.5208L9.33333 9.49998L1.3125 1.47915"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        {/* {size.width}px / {size.height}px/{parseInt(parseInt(size.width) / 330)} */}
      </div>
      <div></div>
    </div>
  );
};
export default Services;

const Properties = ({ images, title, price, details, pid }) => {
  return (
    <div className="proparty-card">
      <a
        href={"/project/" + pid}
        style={{ textDecoration: "none", color: "#222" }}
      >
        <img
          style={{ width: 200, height: 130, borderRadius: "0 20px" }}
          src={images}
          alt="ellip"
        />
        <div className="property-content">{title}</div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: "#212325",
            lineHeight: "21px",
            opacity: "0.6",
            marginLeft: 7,
          }}
        >
          {details.substring(0, Math.min(50, details.length))}
          {details.length < 55 ? "" : " ..."}
        </p>
        <div className="property-amount">{price}</div>
        <div className="project-card-button">{"More Info"}</div>
      </a>
    </div>
  );
};
