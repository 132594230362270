import primaryBannerMainImage from "../../assets/banner-1/Group.png";
import image1 from "../../assets/banner-1/Frame1.png";
import image2 from "../../assets/banner-1/Frame2.png";
import image3 from "../../assets/banner-1/Frame3.png";
import image4 from "../../assets/banner-1/Frame4.png";
import image5 from "../../assets/banner-1/Frame5.png";
import image6 from "../../assets/banner-1/Frame6.png";

const BannerPrimaryInteractiveImage = () => {
  return (
    <div
      style={{
        textAlign: "center",
        // height: 658,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 622,
      }}
    >
      <div
        style={{
          textAlign: "center",
          height: 500,
        }}
      >
        <img
          src={primaryBannerMainImage}
          alt="building pic"
          style={{ textAlign: "center" }}
        />

        <img
          src={image1}
          style={{ position: "relative", top: -554, left: 3 }}
          alt="building pic 1"
        />
        <img
          src={image2}
          style={{ position: "relative", top: -391, left: 370 }}
          alt="building pic 2"
        />
        <img
          src={image3}
          style={{ position: "relative", top: -509, left: 131 }}
          alt="building pic 3"
        />
        <img
          src={image4}
          style={{ position: "relative", top: -549, left: 176 }}
          alt="building pic 4"
        />
        <img
          src={image5}
          style={{ position: "relative", top: -223, left: -329 }}
          alt="building pic 5"
        />
        <img
          src={image6}
          style={{ position: "relative", top: -394, left: -290 }}
          alt="building pic 6"
        />
        {/* */}
      </div>
    </div>
  );
};
export default BannerPrimaryInteractiveImage;
