import WhyUsCard from "./WhyUSCard";
import "./whyUs.css";
import group from "./assets/icons/group.png";
import building from "./assets/icons/building.png";
import satisfaction from "./assets/icons/satisfaction.png";
const WhyUS = () => {
  return (
    <div className="why-us-container" style={{ paddingBottom: 70 }}>
      <div className="why-us-header">Why Us?</div>
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        <WhyUsCard
          image={group}
          mainTitle={"Building Project"}
          subTitle={"Baladi has more than 10+ active construction projects"}
        />
        <WhyUsCard
          image={building}
          mainTitle={"Top Consultants"}
          subTitle={"Baladi has best builder consultant in India"}
        />
        <WhyUsCard
          image={satisfaction}
          mainTitle={"Satisfaction"}
          subTitle={"We have more than 100+ satisfied customers"}
        />
      </div>
    </div>
  );
};
export default WhyUS;
