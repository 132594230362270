import React, { useEffect, useState } from "react";
import KPICard from "./KIPCard";
import batch from "./assets/batch.png";
import building from "./assets/building.png";
import calender from "./assets/calender.png";
import group from "./assets/group.png";
import "./index.css";
import { apiBase } from "../../config/routes.js";
const KPIBanner = () => {
  const [kpiData, setKpiData] = useState({});
  useEffect(() => {
    loadKpi((res) => {
      try {
        setKpiData(JSON.parse(res));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  return (
    <div
      className="kpi-banner-container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/img/kpi-bg.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#000",
        paddingTop: 50,
        paddingBottom: 50,
        // marginBottom: 10,
      }}
    >
      <div
        className="kpi-banner-card-container"
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <KPICard
          title={"Property Build"}
          count={kpiData.propertyBuild}
          image={building}
        />
        <div className="kpi-card-divider"></div>
        <KPICard
          title={"Awards Gained"}
          count={kpiData.awardsGained}
          image={batch}
        />
        <div className="kpi-card-divider"></div>
        <KPICard
          title={"Happy Customers"}
          count={kpiData.happyCustomers}
          image={group}
        />
        <div className="kpi-card-divider"></div>
        <KPICard
          title={"Years Experience"}
          count={kpiData.experiances}
          image={calender}
        />
      </div>
    </div>
  );
};
export default KPIBanner;

function loadKpi(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AXL8NxGD9IzqrQ-EIocCoGlC8wuPcyrLj.EjaIF%2B4HRSqNc0JMFhsktU%2BAg5JfZMcyEIXfANZr%2B%2Fg"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(apiBase + "/Kpis/1", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
