import "./services.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import ServiceCard from "./ServicesCard";
import { useRef } from "react";
import solar from "./assets/icons/solar.png";
import secureCommunity from "./assets/icons/secureCommunity.png";
import resellAssistance from "./assets/icons/resellAssistance.png";
import propartyManagement from "./assets/icons/propartyManagement.png";
import Slider from "react-slick";
import { useState, useEffect } from "react";
const settings1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
};
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Services = () => {
  const size = useWindowSize();
  const sliderRef = useRef();
  return (
    <div className="services" style={{ paddingBottom: 90 }}>
      <div className="services-container">
        <div className="services-header">Services</div>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            // padding: 20,
            // overflow: "hidden",
            // flexWrap: "wrap",
            // border: "1px solid green",
          }}
        >
          <div
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: 0,
            }}
          >
            <div
              style={{
                background: "#fff",
                boxShadow: "1px 2px 4px 2px #cccccc8c",
                minWidth: 50,
                minHeight: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 100,
                position: "relative",
                left: 36,
                zIndex: 999,
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(sliderRef);
                sliderRef.current.slickPrev();
              }}
            >
              <svg
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.1875 1.47919L1.16667 9.50002L9.1875 17.5209"
                  stroke="#5D5F5F"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div style={{ flex: 1, minWidth: 200 }}>
            <div
              style={{
                margin: "auto",
              }}
            >
              <Slider ref={sliderRef} {...settings}>
                <div style={{}}>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={resellAssistance}
                      mainTitle={"Resell Assistance"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>
                <div>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={solar}
                      mainTitle={"Solar Rooftop"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>
                <div>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={propartyManagement}
                      mainTitle={"Property Management"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>
                <div>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={secureCommunity}
                      mainTitle={"Creating Secured Community"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>

                <div>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={propartyManagement}
                      mainTitle={"Property Management"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>
                <div>
                  <div className="slider-service-card-holder">
                    <ServiceCard
                      image={secureCommunity}
                      mainTitle={"Creating Secured Community"}
                      subTitle={"100% best and good quality items "}
                    />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              left: -35,
            }}
          >
            <div
              style={{
                background: "#fff",
                boxShadow: "1px 2px 4px 2px #cccccc8c",
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(sliderRef);
                sliderRef.current.slickNext();
              }}
            >
              <svg
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.3125 17.5208L9.33333 9.49998L1.3125 1.47915"
                  stroke="#5D5F5F"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div>
          {/* {size.width}px / {size.height}px/{parseInt(parseInt(size.width) / 330)} */}
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default Services;

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      console.log("call");
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
