import "./index.css";
import WorkingProcess from "./WorkingProcess";

// import group from "./assets/logo/group.png";
const AboutUs = () => {
  return (
    <div className="about-us" id="about-us">
      <div className="about-us-section" style={{}}>
        <div className="about-us-main-header">About Us</div>
        <div
          className="about-us-container"
          style={{
            // backgroundImage: `url(${
            //   process.env.PUBLIC_URL + "/img/group.png"
            // })`,

            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            // overflow: "auto",
            // height: 420,
          }}
        >
          <div className="about-us-section-1" style={{}}>
            <div className="about-us-section-1-container">
              <div className="about-us-sub-header">
                We
                <span style={{ color: "#F5A023", padding: 5, fontWeight: 500 }}>
                  Build
                </span>{" "}
                Everything
              </div>
              <div className="about-us-content">
                The design of buildings and the space within the site
                surrounding the buildings that have human occupancy or use as
                their principal purpose.The design of buildings and the space
                within the site surrounding the buildings that have human
                occupancy.
              </div>
              <div className="about-us-content">
                The design of buildings and the space within the site
                surrounding the buildings that have human occupancy or use as
                their principal purpose.
              </div>
              <div className="about-us-container-1-image" style={{}}></div>
              <div className="about-us-button">
                <a
                  href="/#contact-info"
                  style={{ textDecoration: "none", color: "#222" }}
                >
                  Make Schedule to talk
                </a>
              </div>
            </div>
          </div>
          <div className="about-us-section-2"></div>
        </div>

        <div
          className="about-us-container-2"
          style={{
            // backgroundImage: `url(${
            //   process.env.PUBLIC_URL + "/img/group2.png"
            // })`,
            marginTop: 100,
            minHeight: 400,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",

            // height: 420,
          }}
        >
          <div className="about-us-section-2-2"></div>
          <div className="about-us-section-1-2" style={{}}>
            <div style={{}}>
              <div className="about-us-header">
                Person at
                <span style={{ color: "#F5A023", padding: 5, fontWeight: 500 }}>
                  Work
                </span>{" "}
              </div>
              <div className="about-us-content">
                The design of buildings and the space within the site
                surrounding the buildings that have human occupancy or use as
                their principal purpose.The design of buildings and the space
                within the site.
              </div>
            </div>
          </div>
          <div className="about-us-section-3"></div>
        </div>

        <WorkingProcess />
      </div>
    </div>
  );
};
export default AboutUs;
