import "./header.css";
const HeaderLink = ({ title, link, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`header-link ${active ? "header-link-active" : ""}`}
    >
      <a
        className={`header-link ${active ? "header-link-active" : ""}`}
        style={{ textDecoration: "none" }}
        href={link}
      >
        {title}
      </a>
    </div>
  );
};

export default HeaderLink;
