import "./whyUs.css";
const WhyUsCard = ({ mainTitle, subTitle, image }) => {
  return (
    <div className="why-us-card-container">
      <div style={{ textAlign: "center" }}>
        <img src={image} alt="icon" />
      </div>
      <div className="why-us-card-main-title">{mainTitle}</div>
      <div className="why-us-card-sub-title">{subTitle}</div>
    </div>
  );
};

export default WhyUsCard;
