import WorkingProcessCard from "./WorkingProcessCard";
import crain from "./assets/icons/crain.png";
import hammer from "./assets/icons/hammer.png";
import okBatch from "./assets/icons/okBatch.png";
import star from "./assets/icons/star.png";
const WorkingProcess = () => {
  return (
    <div style={{}} className="work-in-progress">
      <div
        style={{
          display: "flex",
          paddingTop: 100,
          maxWidth: 1440,
          margin: "auto",
        }}
        className="work-in-progress-container"
      >
        <div style={{ flex: 1 }} className="work-in-progress-content-container">
          <div
            style={{
              fontWeight: 500,
              fontSize: 36,
              lineHeight: "172.2%",
              letterSpacing: "0.045em",
              marginBottom: 20,
            }}
          >
            <span style={{ color: "#F5A023" }}>Working</span> Process
          </div>
          <div
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 18,
              color: "#212325",
              opacity: 0.8,
              lineHeight: "33px",
              marginBottom: 10,
            }}
          >
            The design of buildings and the space within the site surrounding
            the buildings that have human occupancy or use as their principal
            purpose.The design of buildings and the space within the site.
          </div>
          <div
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "33px",
              color: "#212325",
              opacity: 0.8,
            }}
          >
            The design of buildings and the space within the site surrounding
            the buildings that have human occupancy.
          </div>
        </div>
        <div style={{ flex: 1 }} className="work-in-progress-cards-container">
          {[
            {
              icon: okBatch,
              title: "Best Quality",
              description:
                "Baladi has more than 10+ active construction projects",
            },
            {
              icon: crain,
              title: "Advanced Equipments",
              description:
                "The design of buildings and the space within the site surrounding",
            },
            {
              icon: star,
              title: "Top Rated",
              description:
                "Baladi has more than 10+ active construction projects",
            },
            {
              icon: hammer,
              title: "Modern Tools",
              description:
                "The design of buildings and the space within the site surrounding",
            },
          ].map((d, i) => {
            return (
              <WorkingProcessCard
                title={d.title}
                icon={d.icon}
                description={d.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default WorkingProcess;
